<template>
    <section>
        <DataTable :value="listadoOrdenes" @click="checkOrden" v-model:selection="ordenesSeleccionadas" dataKey="DocNumOrden" responsiveLayout="scroll" >
            <Column headerClass="lg:text-xs" selectionMode="multiple" headerStyle="width: 3em"></Column>
            <Column headerClass="lg:text-xs" bodyClass="lg:text-xs" field="DocNumOrden" header="NO. OV"></Column>
            <Column headerClass="lg:text-xs" bodyClass="lg:text-xs" field="Bodega" header="NOM. BODEGA"></Column>
            <Column headerClass="lg:text-xs" bodyClass="lg:text-xs" field="Cliente" header="CLIENTE"></Column>
            <Column headerClass="lg:text-xs" bodyClass="lg:text-xs" field="MotivoAutorizacion" header="MOT. AUTORIZACIÓN"></Column>
            <Column headerClass="lg:text-xs" bodyClass="lg:text-xs" field="DocDate" header="FECHA">
              <template #body="{data}">
                <p class="text-sm">
                  {{dayjs(data.DocDate).format('YYYY-MM-DD')}}
                </p>
              </template>
            </Column>
            <Column headerClass="lg:text-xs" field="DocTotal" header="VLR. TOTAL">
              <template #body="{data}">
                <p class="text-sm">
                  {{$h.formatCurrency(parseInt(data.DocTotal))}}
                </p>
              </template>
            </Column>
            <Column
            header="DETALLE"
            headerClass="lg:text-xs"
            bodyClass="lg:text-xs"
            >
              <template #body="{data}">
                <button @click="openModal(data)">
                  <i class="pi pi-eye text-blue-800"></i>
                </button>
              </template>
            </Column>
        </DataTable>
        <div class="lg:flex text-center justify-center gap-4 items-center">
          <p class="text-gray-600">
            Mostrando de {{totalItemsCount > 1 ? (offset +1) : offset}} a {{totalItemsCount > limit ? (listadoOrdenes.length === limit ? limit + offset : listadoOrdenes.length + offset) : totalItemsCount }} de {{totalItemsCount}}
          </p>
          <Paginator v-model:first="offset"
            :rows="limit"
            :totalRecords="totalItemsCount"
            :rowsPerPageOptions="[2,10,20,30, 100, 500]"
            @page="onPage($event)"
          />
        </div>
        <div class="w-full justify-between md:flex items-center mt-4">
          <div>
            <p class="font-bold">Total de ordenes seleccionadas: {{ordenesSeleccionadas.length}}</p>
          </div>
          <div class="md:flex justify-between w-full lg:w-6/12 items-center">
            <div>
              <p class="font-bold">Total Factura: {{$h.formatCurrency(totalSumaOrdenes)}}</p>
            </div>
            <div>
              <Button @click="exportarExcel" icon="pi pi-file-excel" label="Exportar" class="p-button-success my-2 lg:my-0" />
            </div>
            <div>
              <Button class="my-2 lg:my-0" @click="openModalValidacionOvs" label="Generar facturación" />
            </div>
          </div>
        </div>
        <Dialog v-model:visible="displayModalVerificacionNumeroOvs" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" :modal="true">
          <div class="w-full flex justify-center">
            <svg style="width:100px;height:100px" viewBox="0 0 24 24">
                <path fill="orange" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
            </svg>
          </div>
          <div class="w-full text-center">
            <p class="font-bold text-3xl"> Facturación Evento <span class="text-red-800">{{ comoCapita ? 'Agrupada' : '' }}</span></p>
            <p class="font-bold text-3xl">Total seleccionados: {{ordenesSeleccionadas.length}}</p>
            <p class="font-bold text-3xl my-4">Total factura: {{$h.formatCurrency(totalSumaOrdenes)}}</p>
            <InputNumber inputId="integeronly" v-model="numeroOvSegunUsuario" />
          </div>
            <template #footer>
              <div class="w-full text-center">
                <Button label="Si generar!" icon="pi pi-check" @click="(numeroOvSegunUsuario === ordenesSeleccionadas.length ? facturar() : alertar('El numero de ordenes ingresado no coincide con el seleccionado', 1))" autofocus />
                <Button label="No, Cancelar!" icon="pi pi-times" @click="(displayModalVerificacionNumeroOvs = false)" class="bg-gray-600 border-0"/>
              </div>
            </template>
        </Dialog>
        <Dialog v-model:visible="displayModalDetalleOv" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" :modal="true">
          <template #header>
            <div class="flex items-center">
              <i class="pi pi-list mr-3"></i>
              <p>
                Entregas de la orden <span class="font-bold">{{dataModalEntregas.DocNumOrden}}</span>
              </p>
            </div>
          </template>
          <div class="w-full p-4">
            <DataTable
                :value="entregas"
                v-model:expandedRows="expandedRows"
                dataKey="DocNum"
                @rowExpand="onRowExpand"
                responsiveLayout="scroll">
                <Column bodyClass="text-sm" headerClass="text-sm" :expander="true" headerStyle="width: 3rem; background-color: white;" />
                <Column bodyClass="text-sm" headerClass="text-sm" field="DocNum" headerStyle="background-color: white;" header="Entrega"></Column>
                <Column bodyClass="text-sm" headerClass="text-sm" headerStyle="background-color: white;" header="Fecha">
                    <template #body="slotProps">
                      {{slotProps.data.Fecha}}
                    </template>
                </Column>
                <Column bodyClass="text-sm" headerClass="text-sm" headerStyle="background-color: white;" header="Valor">
                    <template #body="slotProps">
                      {{$h.formatCurrency(parseInt(slotProps.data.Valor))}}
                    </template>
                </Column>
                <template #expansion="slotProps">
                  <div class="orders-subtable">
                    <DataTable class="p-datatable-sm" :value="slotProps.data.medicamentos" responsiveLayout="scroll">
                        <Column bodyClass="text-sm" headerClass="text-sm" field="ItemCode" header="Medicamento"></Column>
                        <Column bodyClass="text-sm" headerClass="text-sm" field="Dscription" header="Descripción"></Column>
                        <Column bodyClass="text-sm" headerClass="text-sm" header="Cantidad">
                          <template #body="slotProps">
                            {{parseInt(slotProps.data.Quantity)}}
                          </template>
                        </Column>
                        <Column bodyClass="text-sm" headerClass="text-sm" header="Amount">
                          <template #body="slotProps">
                            {{$h.formatCurrency(parseInt(slotProps.data.LineTotal))}}
                          </template>
                        </Column>
                      </DataTable>
                  </div>
                </template>
            </DataTable>
          </div>
        </Dialog>
        <Dialog header="Ordenes con medicamentos inactivos" v-model:visible="displayModalValidacionMds" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" :modal="true">
          <div class="w-full">
            <p class="font-bold"> Las siguientes ordenes contienen medicamentos inactivos: </p>
            <div v-for="(md, o) of ordenesMdsInactivos" :key="o">
              <div class="w-full flex items-center mt-4 font-medium text-red-600">
                <i class="pi pi-info-circle mr-2"></i>
                Orden: {{ md.OV }} - Medicamento: {{ md.ItemCode }}
              </div>
            </div>
            <div class="w-full flex justify-end gap-4">
              <Button label="Cancelar" @click="displayModalValidacionMds = false" class="p-button-outlined p-button-secondary" />
              <Button @click="facturarExcluidaEvento" label="Excluir ordenes y continuar" />
            </div>
          </div>
        </Dialog>
    </section>
</template>
<script>
    import OrdenesService from '../../../services/ordenes.service'
    import ExportarExcel from '../../../services/exportar_excel.service'
    import facturacionStore from '../../../store/facturacion.store'
    import { computed, onMounted, ref, watch } from 'vue'
    import Swal from 'sweetalert2'
    import sumBy from 'lodash/sumBy'
    import dayjs from 'dayjs'
    import GenerarFacturasService from '../../../services/generarFacturas.service'
    export default {
      name: 'facturacionMasivaEvento',
      setup () {
        // Services
        const _OrdenesService = ref(new OrdenesService())
        const _GenerarFacturasService = ref(new GenerarFacturasService())
        // References
        const offset = ref(0)
        const page = ref(0)
        const limit = ref(10)
        const totalItemsCount = ref(0)
        const listadoOrdenes = ref([])
        const ordenesSeleccionadas = ref([])
        const ordenesFacturar = ref([])
        const totalSumaOrdenes = ref(0)
        const displayModalDetalleOv = ref(false)
        const displayModalValidacionMds = ref(false)
        const dataModalEntregas = ref({})
        const expandedRows = ref([])
        const numeroOvSegunUsuario = ref(0)
        const displayModalVerificacionNumeroOvs = ref(false)
        const entregas = ref([])
        const ordenesMdsInactivos = ref([])
        const ordenesListasEnviar = ref()
        const dataListaEnviarComoCapita = ref()
        // Computed
        const filtrosEnviar = computed(() => facturacionStore.getters._filtros)
        const comoCapita = computed(() => facturacionStore.getters._comoCapita)
        const dataComoCapita = computed(() => facturacionStore.getters._dataFacturarEventoCapita)
        const params = computed(() => {
          return {
            offset: offset.value,
            page: page.value,
            limit: limit.value,
            filters: filtrosEnviar.value
          }
        })
        // Data
        const listarOrdenesVenta = () => {
          _OrdenesService.value.evento(params.value).then(({ data }) => {
            ordenesSeleccionadas.value = []
            totalItemsCount.value = data.total
            listadoOrdenes.value = data.rows
          })
        }
        // Methods
        const checkOrden = () => {
          ordenesFacturar.value = []
          for (const entrega of listadoOrdenes.value) {
            if (ordenesSeleccionadas.value.length) {
              for (const orden of ordenesSeleccionadas.value) {
                if (orden.DocNumOrden === entrega.DocNumOrden) {
                  if (ordenesFacturar.value.length) {
                    if (orden.DocNumEntrega !== entrega.DocNumEntrega) {
                      ordenesFacturar.value.push({
                        DocNum: entrega.DocNumOrden,
                        DocTotal: entrega.DocTotal,
                        _key: ordenesFacturar.value.length,
                        baseEntrys: entrega.BaseEntry
                      })
                      ordenesSeleccionadas.value.push(entrega)
                    }
                  } else {
                    ordenesFacturar.value.push({
                      DocNum: entrega.DocNumOrden,
                      DocTotal: entrega.DocTotal,
                      _key: ordenesFacturar.value.length,
                      baseEntrys: entrega.BaseEntry
                    })
                  }
                }
              }
            }
          }
          totalSumaOrdenes.value = 0
          ordenesSeleccionadas.value.map(a => {
            totalSumaOrdenes.value += parseInt(a.DocTotal)
          })
        }
        const openModal = (info) => {
          _OrdenesService.value.entregas({ ov: info.DocNumOrden }).then(({ data }) => {
            entregas.value = data
            entregas.value.map(a => {
              a.medicamentos = []
            })
          })
          displayModalDetalleOv.value = true
          dataModalEntregas.value = info
        }
        const openModalValidacionOvs = () => {
            if (Object.values(dataComoCapita.value).some(a => !a) && comoCapita.value) {
              alertar('Ingrese todos los datos de la factura')
              facturacionStore.commit('setAbrirMenuEventoCapita', true)
            } else {
              (ordenesSeleccionadas.value.length ? displayModalVerificacionNumeroOvs.value = true : alertar('Debe seleccionar una orden', 1))
            }
        }
        const facturar = () => {
          displayModalVerificacionNumeroOvs.value = false
          const array = []
          ordenesSeleccionadas.value.map(a => {
            array.push(a.DocNumOrden)
          })
          if (comoCapita.value) {
            _GenerarFacturasService.value.validarMds(array).then(({ data }) => {
              ordenesMdsInactivos.value = data
              const object = {
                ordenes: array,
                U_PHR_MesCapita: dataComoCapita.value.mes,
                U_PHR_MunicipioF: dataComoCapita.value.localizacion.Municipio,
                U_PHR_DptoAfiliacion: dataComoCapita.value.localizacion.Departamento,
                U_PHR_Desc1: dataComoCapita.value.comentario
              }
              facturacionValidadaEvento(object)
            })
          } else {
            _GenerarFacturasService.value.validarMds(array).then(({ data }) => {
              ordenesMdsInactivos.value = data
              facturacionValidadaEvento(array)
            })
          }
        }
        const facturacionValidadaEvento = (array) => {
          if (comoCapita.value) {
            dataListaEnviarComoCapita.value = array
            ordenesListasEnviar.value = array.ordenes
            if (ordenesMdsInactivos.value.length) {
              displayModalValidacionMds.value = true
            } else {
              _GenerarFacturasService.value.eventoCapita(array).then(({ data }) => {
                if (data.error) {
                  Swal.fire(
                    'Error',
                    'A ocurrido un error al facturar',
                    'error'
                  )
                } else {
                  Swal.fire(
                    'Guardado',
                    'Las ordenes se estan procesando, por favor revise su historial para ver el seguimiento',
                    'success'
                  ).then(() => {
                    listarOrdenesVenta()
                    displayModalVerificacionNumeroOvs.value = false
                    ordenesSeleccionadas.value = []
                    numeroOvSegunUsuario.value = 0
                  })
                }
              })
            }
          } else {
            ordenesListasEnviar.value = array
            if (ordenesMdsInactivos.value.length) {
              displayModalValidacionMds.value = true
            } else {
              _GenerarFacturasService.value.evento(array).then(({ data }) => {
                if (data.error) {
                  Swal.fire(
                    'Error',
                    'A ocurrido un error al facturar',
                    'error'
                  ).then(() => {
                    listarOrdenesVenta()
                    displayModalVerificacionNumeroOvs.value = false
                  })
                } else {
                  Swal.fire(
                    'Guardado',
                    'Las ordenes se estan procesando, por favor revise su historial para ver el seguimiento',
                    'success'
                  ).then(() => {
                    listarOrdenesVenta()
                    displayModalVerificacionNumeroOvs.value = false
                    ordenesSeleccionadas.value = []
                    numeroOvSegunUsuario.value = 0
                  })
                }
              })
            }
          }
        }
        const facturarExcluidaEvento = () => {
          const array = []
          array.push(...ordenesListasEnviar.value)
          const ordernesExcluir = []
          for (const i of ordenesMdsInactivos.value) {
            ordernesExcluir.push(i.OV)
          }
          for (const i of ordenesListasEnviar.value) {
            if (ordernesExcluir.includes(i)) {
              const index = array.findIndex(a => a === i)
              array.splice(index, 1)
            }
          }
          if (comoCapita.value) {
            dataListaEnviarComoCapita.value.ordenes = array
            if (array.length) {
              _GenerarFacturasService.value.eventoCapita(dataListaEnviarComoCapita.value).then(({ data }) => {
                if (data.error) {
                  Swal.fire(
                    'Error',
                    'A ocurrido un error al facturar',
                    'error'
                  )
                } else {
                  Swal.fire(
                    'Guardado',
                    'Las ordenes se estan procesando, por favor revise su historial para ver el seguimiento',
                    'success'
                  ).then(() => {
                    listarOrdenesVenta()
                    displayModalVerificacionNumeroOvs.value = false
                    ordenesSeleccionadas.value = []
                    numeroOvSegunUsuario.value = 0
                    displayModalValidacionMds.value = false
                  })
                }
              })
            } else {
              Swal.fire(
                'Advertencia',
                'No tienes ordenes por enviar, ya que la cantidad excluida es igual a la cantidad enviada',
                'warning'
              ).then(() => {
                displayModalValidacionMds.value = false
              })
            }
          } else {
            if (array.length) {
              _GenerarFacturasService.value.evento(array).then(({ data }) => {
                if (data.error) {
                  Swal.fire(
                    'Error',
                    'A ocurrido un error al facturar',
                    'error'
                  ).then(() => {
                    listarOrdenesVenta()
                    displayModalVerificacionNumeroOvs.value = false
                    displayModalValidacionMds.value = false
                  })
                } else {
                  Swal.fire(
                    'Guardado',
                    'Las ordenes se estan procesando, por favor revise su historial para ver el seguimiento',
                    'success'
                  ).then(() => {
                    listarOrdenesVenta()
                    displayModalVerificacionNumeroOvs.value = false
                    ordenesSeleccionadas.value = []
                    numeroOvSegunUsuario.value = 0
                    displayModalValidacionMds.value = false
                  })
                }
              })
            } else {
              Swal.fire(
                'Advertencia',
                'No tienes ordenes por enviar, ya que la cantidad excluida es igual a la cantidad enviada',
                'warning'
              ).then(() => {
                displayModalValidacionMds.value = false
              })
            }
          }
        }
        const onRowExpand = (event) => {
          _OrdenesService.value.medicamentosEntrega({ DocEntryEntrega: event.data.DocEntryEntrega }).then(({ data }) => {
            for (const entrega of entregas.value) {
              if (entrega.DocNum === event.data.DocNum) {
                entrega.medicamentos = data
              }
            }
          })
        }
        const alertar = (mensaje, tipo) => {
          const icon = tipo === 1 ? 'warning' : 'warning'
          Swal.fire({
            icon: icon,
            title: ` <span style="line-height: normal;">${mensaje}</span>`
          })
        }
        const onPage = ({ first, page, pageCount, rows }) => {
          limit.value = rows
          // listarOrdenesVenta(params.value)
        }
        const exportarExcel = () => {
          return ExportarExcel(params.value)
        }
        watch(filtrosEnviar, (n, v) => {
          if (n) {
            if (!n.limpiar) {
              n.CodCliente.length ? listarOrdenesVenta() : alertar('Debe seleccionar un cliente', 1)
            } else {
              listarOrdenesVenta()
            }
          }
        })
        onMounted(() => {
          // listarOrdenesVenta()
          facturacionStore.commit('setComoCapita', false)
        })
        return {
          filtrosEnviar,
          offset,
          limit,
          page,
          listadoOrdenes,
          ordenesSeleccionadas,
          totalItemsCount,
          dayjs,
          displayModalDetalleOv,
          openModal,
          onPage,
          checkOrden,
          ordenesFacturar,
          sumBy,
          totalSumaOrdenes,
          displayModalVerificacionNumeroOvs,
          numeroOvSegunUsuario,
          alertar,
          facturar,
          exportarExcel,
          dataModalEntregas,
          entregas,
          onRowExpand,
          expandedRows,
          comoCapita,
          dataComoCapita,
          openModalValidacionOvs,
          ordenesMdsInactivos,
          displayModalValidacionMds,
          facturarExcluidaEvento,
          ordenesListasEnviar
        }
      }

    }
</script>
<style scoped>
:global(.swal2-container) {z-index: 1000000 !important;}
</style>
